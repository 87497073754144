<template src="./payment.html" />

<script>
import { computed } from "vue"
import { useStore } from "@/store/store.js"
import { paymentMethods } from "@/constants/payment-methods.js"

import CreditCard from "@/components/creditCard/creditCard.vue";
import Pix from "@/components/pix/pix.vue";

import imgBankSlip from "@/assets/svgs/myPlan/planUpgrade/shoppingCart/paymentMethods/bank-slip.svg"
import imgCreditCard from "@/assets/svgs/myPlan/planUpgrade/shoppingCart/paymentMethods/credit-card.svg"
import imgPix from "@/assets/svgs/myPlan/planUpgrade/shoppingCart/paymentMethods/pix.svg"

export default {
  name: "Payment",

  props: {
    disablePaymentMethods: {
      type: Array,
      default: () => ["bank-slip"],
    },
  },

  components: {
    CreditCard,
    Pix,
  },
  
  data() {
    return {
      activeMethod: "",
      benefits: [
        {
          description: "Telemedicina 24 horas",
          value: 24.9,
        },
        {
          description: "Acesso imediato",
          value: 0,
        },
        {
          description: "24 horas/dia - 7 dias/semana",
          value: 0,
        },
        {
          description: "Equipe médica especializada em emergências e treinada para atendimento virtual",
          value: 0,
        },
        {
          description: "Consulta por celular ou computador, de qualquer lugar.",
          value: 0,
        },
        {
          description: "Receitas médicas e atestados digitais.",
          value: 0,
        },
      ],
      paymentMethods: [],
      plan: () => ({}),
      codeGenerated: false,
      hideFinalize: false,
      isLoading: false,
      isAwareOfConditions: undefined,
    };
  },

  computed: {
    services () {
      return this.plan.planServices.filter((service) => service.active)
    },
    dependents () {
      return this.plan.type === "familiar" ? 3 : 0
    },
    value () {
      return this.plan.planValue
    },
    subscriptionData () {
      const data = {
        dependents: this.dependents,
        isPlan: true,
        value: this.value,
        plan: {},
      }

      if (this.plan) {
        if (this.plan.id) {
          data.plan.id = this.plan.id
        }
        if (this.plan.type) {
          data.plan.type = this.plan.type
        }
      }
      
      return data
    },
  },
  
  setup () {
    const store = useStore()

    return {
      device: computed(() => store.getters.device),
      payIcons: [ imgBankSlip, imgCreditCard, imgPix ],
      getPlanByCode: (code) => store.dispatch("getPlanByCode", code),
    }
  },

  async created () {
    this.plan = this.$session.get("activation")
    
    this.paymentMethods = paymentMethods.map((payment) => ({
      ...payment,
      image: this.payIcons.find((img) => img.indexOf(`/${payment.slug}`) > 0),
    }))
  },

  async mounted () {
    // initialize component with first available payment method
    await this.$nextTick(() => {
      let payments = this.paymentMethods.filter((payment) => (
        !this.disablePaymentMethods.includes(payment.slug)
      ))

      if (!payments.length) {
        payments = this.paymentMethods.filter((payment) => payment.slug === "credit-card")
      }

      this.activeMethod = payments[0].slug
    })
  },

  methods: {
    formatMoney (value) {
      if (value) {
        return value.toLocaleString("pt-br", { minimumFractionDigits: 2 })
      }
      return null
    },

    handleClickPayment (msg) {
      alert(msg)
    },

    handlePaymentTypeExchange (method) {
      this.activeMethod = method.slug
      if (this.codeGenerated && method.slug === "pix") {
        this.hideFinalize = true
        return
      } 
      this.hideFinalize = false
    },
    
    validateFields () {
      if (!this.isAwareOfConditions) {
        this.isAwareOfConditions = false
      }
      
      if (this.activeMethod === "credit-card") {
        this.$refs.creditCard.validateFields()
      }

      if (this.activeMethod === "pix") {
        this.$refs.pix.validateSubmission()
      }
    },

    updateCodeGenerated () {
      this.codeGenerated = true
      this.hideFinalize = true
    },

    async switchLoading() {
      this.isLoading = !this.isLoading
    
      await this.$nextTick(() => {
        if (this.isLoading) {
          this.$vs.loading()
        } else {
          this.$vs.loading.close()
        }
      })
    },
  },
}
</script>

<style lang="scss" src="./payment.scss" />
