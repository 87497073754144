<template lang="html" src="./pix.html"/>
<script>
import mixins from "@/mixins/mixins";
import QrcodeVue from "qrcode.vue";
export default {
  mixins: [mixins],
  components: {
    "qrcode-vue": QrcodeVue,
  },
  props:{
    subscriptionData: {
      type: Object,
      required: true,
    },
    disableFinalize: {
      type: Boolean,
      default: false,
    },
    codeGenerated: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      windowWidth: 0,
      webScreen: true,
      mobileScreen: false,
      clickedAddonsId: null,
      totalShoppingCart: 0,
      cpf: null,
      invalidCPF: false,
      isPlan: false,
      alertMessageCPF: "",
      customer: null,
      planId: null,
      payment: "",
      isDisable: false,
      disabledField: true,
      qrCodePix: "www.filoo.com.br",
      sizeQrCode: 175,
      invoice: null,
      busy: false,
      copyAndPasteMsg: "",
    }
  },
  mounted() {
    const storedCode = this.$session.get("pixCode");
    if (storedCode) {
      this.qrCodePix = storedCode
      this.$emit("code-generated")
    }

    this.getCustomerData()
  },
  created: function () {
    window.addEventListener("resize", this.handleResize)
    this.handleResize()
  },
  destroyed: function () {
    window.removeEventListener("resize", this.handleResize)
  },
  methods: {
    handleResize() {
      this.windowWidth = document.documentElement.clientWidth
      
      this.sizeQrCode = 100
      this.webScreen = false
      this.mobileScreen = true

      if (this.windowWidth > 371 && this.windowWidth < 788) {
        this.sizeQrCode = 120
        this.webScreen = false
        this.mobileScreen = true
      }

      if (this.windowWidth < 788) {
        this.sizeQrCode = 175
        this.webScreen = true
        this.mobileScreen = false
      }
    },
    getCustomerData(){
      this.customer = this.$session.get("customerData")
      this.clickedAddonsId = this.$props.subscriptionData.clickedAddonsId
      this.totalShoppingCart = this.$props.subscriptionData.totalOnlyNewSelectedAddons

      this.cpf = !this.testNullUndefinedEmpty(this.customer)
        ? this.maskCpf(this.customer.cpf)
        : null
      
      this.disabledField = !this.testNullUndefinedEmpty(this.cpf)

      this.isPlan = this.$props.subscriptionData.isPlan
      this.planId = this.$props.subscriptionData.plan.id
    },
    validateSubmission(){
      this.busy = true
      this.isDisable = true
      this.invalidCPF = false
      this.copyAndPasteMsg = ""

      this.$vs.loading({
        container: "#div-with-loading",
        scale: 1,
      })

      if (this.testNullUndefinedEmpty(this.cpf)) {
        this.invalidCPF = true
        this.alertMessageCPF = "Campo obrigatório"
      } else if (!this.validateCPF(this.cpf)) {
        this.invalidCPF = true
        this.alertMessageCPF = "Insira um CPF válido"
      }

      if(!this.invalidCPF){
        this.$emit("clickNextStep")

        // this.$gtm.trackEvent({
        //   // click_btn_pc_carrinhoupgrade_comprar
        //   event: "plan_upgrade_kart_buy_btn_click",
        //   category: "Pagamento Upgrade",
        //   action: "plan_upgrade_kart_buy_btn_click",
        //   params: {
        //     paymentMethod: "pix",
        //     services: !this.testNullUndefinedEmpty(this.$props.subscriptionData.addons) ? this.$props.subscriptionData.addons.filter(a => a.checked == true) : null,
        //     value: this.totalShoppingCart,
        //     planId: this.planId,
        //   },
        //   label: "Na página  pagamento do upgrade de plano - Clicar no botão ‘Finalizar’",
        // })

        this.$bvModal.show("modal-upgrade-confirmation")
      } else {  
        setTimeout( ()=> {
          this.$vs.loading.close("#div-with-loading > .con-vs-loading")
          this.busy = false
        }, 1000)
      }
    },
    setBusyFalse(){
      setTimeout(() => {
        this.busy = false
      }, 300)
    },
    copyAndWrite() {
      navigator.clipboard.writeText(this.qrCodePix)
      this.copyAndPasteMsg = "Código pix copiado com sucesso!"
    },
    payWithPix() {
      const activation = this.$session.get("activation")

      this.$bvModal.hide("modal-upgrade-confirmation")
      const url = !this.isPlan
        ? "patient/upgrade-services-plan"
        : "/patient/patient-upgrade-plan"
      const textError =
        "Não foi possível gerar o QR code para o pagamento via pix"

      const dataCustomer = {
        patientId: this.customer.patientId,
        services: activation.id,
        chargeRequest: {
          method: "pix",
          token: null,
          amount: this.totalShoppingCart,
          email: this.customer.email,
        },    
      }

      if(this.isPlan){
        dataCustomer.customerPlanId = this.planId
      }

      this.isDisable = true

      setTimeout(() => {
        this.busy = true
      }, 100)

      this.axios.post(this.$store.state.filooServer+url, dataCustomer)
        .then(async ({ status, data }) => {
          if (status === 200) {
            this.$session.set("pixCode", data.pix.qrcode);
            this.qrCodePix = data.pix.qrcode
            this.payment = "disable-button"
            localStorage.removeItem("planAndAddOnsData")
            this.$vs.loading.close("#div-with-loading > .con-vs-loading")
            this.$emit("code-generated")
          }
        })
        .catch((error) => {
          this.isDisable = false
          this.showNotifyError(textError, 6000)

          console.error({
            method: "payWithPix",
            endpoint: url,
            "sent-object": dataCustomer,
            "http-method": "POST",
            error: error,
          })
          
          setTimeout( ()=> {
            this.$vs.loading.close("#div-with-loading > .con-vs-loading")
          }, 500)
        })
    },
  },
}
</script>

<style lang="scss" src="./pix.scss" />
