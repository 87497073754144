const paymentMethods = [
  {
    slug: "bank-slip",
    icon: "bank-slip",
    alt: "Ícone para selecionar pagamento com Boleto Bancário",
  },
  {
    slug: "credit-card",
    icon: "credit-card",
    alt: "Ícone para selecionar pagamento com Cartão de crédito",
  },
  {
    slug: "pix",
    icon: "pix",
    alt: "Ícone para selecionar pagamento com Pix",
  },
]

export {
  paymentMethods
}
